'use strict';

document.addEventListener('DOMContentLoaded', function () {
    const apiKey = localStorage.getItem('apiKey');
    if (apiKey) {
        document.getElementById('apiKey').value = apiKey;
        document.getElementById('positionsLink').style.visibility = 'visible';
        document.getElementById('positionsLink').href = 'positions.html';
    }
});

document.getElementById('apiKeyForm').addEventListener('submit', function (event) {
    event.preventDefault();
    const apiKey = document.getElementById('apiKey').value;

    if (apiKey) {
        localStorage.setItem('apiKey', apiKey);

        // show the link if the API key is valid
        document.getElementById('positionsLink').style.visibility = 'visible';
        document.getElementById('positionsLink').href = 'positions.html';
    } else {
        alert('Please enter an API key.');

    }
});